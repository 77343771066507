import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { Contract } from 'src/app/core/models/contract.model';
import { Curriculum, CurriculumResponse } from 'src/app/core/models/curriculum.model';
import { Faculty } from 'src/app/core/models/faculty.model';
import { IndirectCost } from 'src/app/core/models/indirect_cost.model';
import { Minute } from 'src/app/core/models/minute.model';
import { Project } from 'src/app/core/models/project.model';
import { RubricApplication } from 'src/app/core/models/rubrica.model';
import { SupportLetter } from 'src/app/core/models/support_letter.model';
import { ContractService } from 'src/app/core/services/contract.service';
import { CurriculumService } from 'src/app/core/services/curriculum.service';
import { IndirectCostService } from 'src/app/core/services/indirect-cost.service';
import { MinuteService } from 'src/app/core/services/minute.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { RubricAplicationService } from 'src/app/core/services/rubric-aplication.service';
import { SupportLetterService } from 'src/app/core/services/support-letter.service';
import { BasePage } from 'src/app/shared/base-page';


export interface Semester{
  name: string;
  year: number;
  semester: number;
}

export interface OtherAcademicsView{
  name: string;
  rut: string;
  activities: OtherAcademicsViewActivity[];
}

export interface OtherAcademicsViewActivity{
  objective: string;
  start_date: Date;
  end_date: Date;
}

@Component({
  selector: 'app-proyecto-detalle',
  templateUrl: './proyecto-detalle.component.html',
  styleUrls: ['./proyecto-detalle.component.scss']
})
export class ProyectoDetalleComponent extends BasePage implements OnInit{

  idProject!: number;
  project?: Project;
  breadCrumbItems!: Array<{}>;
  otherAcademics: OtherAcademicsView[] = [];
  supportLetters: SupportLetter[] = [];
  curriculums: CurriculumResponse[] = [];
  allAcademics: any[] = [];
  evals: RubricApplication[] = [];
  minutes: Minute[] = [];
  indirectCosts: IndirectCost[] = [];
  contracts: Contract[] = [];

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private supportLetterService: SupportLetterService,
    private curriculumService: CurriculumService,
    private rubricAplicationService: RubricAplicationService,
    private minuteService: MinuteService,
    private indirectCostService: IndirectCostService,
    private contractService: ContractService,
    injector: Injector) 
  { super(injector); }

  async ngOnInit() {

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Proyecto' },
      { label: 'Ver Proyecto', active: true }
    ];

    this.openLoading();

    this.route.params.pipe(
      switchMap(params => {
          this.idProject = params['id'];

          if (this.idProject) {
            return this.projectService.getProject(this.idProject);
          }

          // If there's no idUser, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: async(response) => {
            if (response) {
                this.project = response;

                this.supportLetters = await this.supportLetterService.getSupportLettersByProject(this.project.id);
                this.curriculums = await this.curriculumService.getCurriculums();

                this.allAcademics = [];

                this.evals = await this.rubricAplicationService.getRubricAplications(this.project.id);
                this.minutes = await this.minuteService.getMinuteByProject(this.project.id);
                this.indirectCosts = await this.indirectCostService.getIndirectCostsByProject(this.project.id);
                this.contracts = await this.contractService.getContractsByProject(this.project.id);

                for(let pe of this.project!.people_estimation!){
                  const curriculum = this.curriculums.find(c => c.rut === pe.rut);
                  this.allAcademics.push({
                    name: pe.name,
                    rut: pe.rut,
                    faculty: pe.faculty!.acronym,
                    hasCurriculum:!!curriculum,
                    curriculumId: curriculum? curriculum.id : null,
                  });
                }

                //bundle all other academics, grouped by rut
                const otherAcademics: OtherAcademicsView[] = [];
                for(let oa of this.project!.other_academics!){
                  const foundOA = otherAcademics.find(oa => oa.rut === oa.rut);
                  if(!foundOA){
                    otherAcademics.push({
                      name: '',
                      rut: oa.rut,
                      activities: [
                        {
                          objective: oa.objective,
                          start_date: oa.start_date,
                          end_date: oa.end_date,
                        },
                      ],
                    });
                  }
                  if(foundOA){
                    foundOA.activities.push({
                      objective: oa.objective,
                      start_date: oa.start_date,
                      end_date: oa.end_date,
                    });
                  }
                }
                this.otherAcademics = otherAcademics;
            }

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Project:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
 
  }

  get percentage(){
    let percentage = 0;

    //curriculums
    if(this.amount_of_academics_with_curriculum > 0 && this.allAcademics.length > 0){
      percentage += Math.round(( this.amount_of_academics_with_curriculum / this.allAcademics.length) * 10);
    }
    
    //support letters
    if(this.amount_of_support_letters_signed > 0 && this.supportLetters.length > 0) {
      percentage += Math.round((this.amount_of_support_letters_signed / this.supportLetters.length) * 20);
    }
    
    //evaluations
    if(this.amount_of_evals_submitted > 0 && this.evals.length > 0) {
      percentage += Math.round((this.amount_of_evals_submitted / this.evals.length) * 30);
    }
    
    //actas consejo
    if(this.amount_of_minutes_saved > 0 && this.minutes.length > 0) {
      percentage += Math.round((this.amount_of_minutes_saved / this.minutes.length) * 10);
    }
    
    //costos indirectos
    if(this.amount_of_indirect_costs_signed > 0 && this.indirectCosts.length > 0) {
      percentage += Math.round((this.amount_of_indirect_costs_signed / this.indirectCosts.length) * 20);
    }
   
    //contratos
    if(this.amount_of_contracts_signed > 0 && this.contracts.length > 0) {
      percentage += Math.round((this.amount_of_contracts_signed / this.contracts.length) * 10);
    }
    
    return percentage;
    
  }

  getProjectTypeLabel(projectType: string): string {
    switch (projectType) {
      case 'is_initial':
        return 'Iniciación (hasta 12 meses)';
      case 'is_regular':
        return 'Regular (hasta 24 meses)';
      case 'is_technology_transfer':
        return 'Transf. Tec. (hasta 24 meses)';
      case 'is_related_music':
        return 'Música';
      case 'is_related_literature':
        return 'Literatura';
      case 'other_related':
        return 'Otro (Indicar)';
      default:
        return '';
    }
  }

  getFacultyName(faculty: Faculty | undefined): string {
    // logic to get faculty name by id
    return '';
  }

  getContractTypeName(contractTypeId: number| undefined): string {
    // logic to get contract type name by id
    return '';
  }

  getGradeName(grade: string| undefined): string {
    // logic to get grade name by id
    return '';
  }

  getObjectiveName(objectiveId: string| undefined): string {
    // logic to get objective name by id
    return '';
  }

  getPersonTotalHours(person:any): number {
    // logic to calculate total hours for a person
    return 0;
  }

  getTotalHours(): number {
    // logic to calculate total hours
    return 0;
  }

  get semesters(): Semester[] {
    //Todo: Get starting semester:
    let currentSemester = 2;
    let currentYear = 2024;
    let current_months = 0;

    const semesters: Semester[] = [];

    while(current_months < this.project!.estimated_duration_months!){
      // Get current semester
      semesters.push({
        name: `${currentYear} - ${currentSemester.toString().padStart(2, '0')}`,
        year: currentYear,
        semester: currentSemester
      });
      //advance to next semester
      currentSemester++;
      if(currentSemester > 2){
        currentSemester = 1;
        currentYear++;
      }
      current_months += 6;
    }

    return semesters;
  }
  
  get amount_of_support_letters_signed(){
    return this.supportLetters.filter(sl => sl.isSigned).length;
  }

  get amount_of_academics_with_curriculum(){
    return this.allAcademics.filter(a =>a.hasCurriculum).length;
  }

  get amount_of_evals_submitted(){
    return this.evals.filter(e => e.submitted).length;
  }

  get amount_of_minutes_saved(){
    return this.minutes.filter(m => m.status !== null).length;
  }

  get amount_of_indirect_costs_signed(){
    return this.indirectCosts.filter(ic => ic.isSigned).length;
  }

  get amount_of_contracts_signed(){
    return this.contracts.filter(c => c.isSigned).length;
  }

  goToNewCV(rut: string) {
    this.router.navigateByUrl('/curriculos/crear/'+rut);
  }

  goToEditCV(id: number) {
    this.router.navigateByUrl('/curriculos/editar/'+id);
  }

  goToSupportLetter(id: number) {
    this.router.navigateByUrl('/cartas-apoyo/editar/'+id);
  }

  goToEval(id: number, type: string) {
    if(type === 'PEER'){
      this.router.navigateByUrl('/evaluacion-pares/'+id);
    } else {
      this.router.navigateByUrl('/evaluacion-dirinves/'+id);
    }
  }

  goToViewEval(id: number, type: string) {
    if(type === 'PEER'){
      this.router.navigateByUrl('/evaluacion-pares/view/'+id);
    } else {
      this.router.navigateByUrl('/evaluacion-dirinves/view/'+id);
    }
  }

  goToIndirectCosts(id:number) {
    this.router.navigateByUrl('/costos-indirectos/editar/'+id);
  }

  goToContract(id:number) {
    this.router.navigateByUrl('/contratos/editar/'+id);
  }

  goToMinute(id: number) {
    this.router.navigateByUrl('/actas/editar/'+id); 
  }

  goToPR() {
    this.router.navigateByUrl('/informe-avance/crear');
  }

}
