<!-- start page title -->
<app-pagetitle title="Creando Currículum" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form [formGroup]="formCurriculum">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="name" placeholder="Ej. PEREZ RODRIGUEZ, Juan Alberto" formControlName="name">
                                <label for="name">Nombre Investigador</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="rut" placeholder="Ej. 11111111-1" formControlName="rut">
                                <label for="rut">RUT</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="email" placeholder="Ej. investigador@unach.cl" formControlName="email">
                                <label for="email">Correo Electrónico</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="phone" placeholder="Ej. 42 304 6543" formControlName="phone">
                                <label for="phone">Teléfono</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="mobile" placeholder="Ej. 9 3045 6543" formControlName="mobile">
                                <label for="mobile">Celular</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelectFacu" formControlName="faculty" name="faculty" required>
                                    <option value="">Eliga una Facultad / Unidad</option>
                                    <option *ngFor="let faculty of faculties" [value]="faculty.id">
                                        {{faculty.name}}
                                    </option>
                                </select>
                                <label for="floatingSelectFacu">Facultad / Unidad</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelect" formControlName="career" name="career" required>
                                    <option value="">Eliga un Programa / Carrera</option>
                                    <option *ngFor="let career of careersForFaculty(faculty)" [value]="career.id">
                                        {{career.name}}
                                    </option>
                                </select>
                                <label for="floatingSelect">Programa / Carrera</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-12">
                            <h4>Títulos Académicos</h4>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="titles">
                                    <thead>
                                        <tr>
                                            <th>Año</th>
                                            <th>Grado Académico Alcanzado</th>
                                            <th>Institución Académica</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of titles.controls; let i = index" [formGroupName]="i">
                                            <td>
                                                <input type="text" class="form-control" formControlName="year" placeholder="Ej. 2010">
                                            </td>
                                            <td>
                                                <select class="form-select" formControlName="degree" required>
                                                    <option value="">Eliga un Grado</option>
                                                    <option value="LIC">Licenciado</option>
                                                    <option value="MG">Magíster</option>
                                                    <option value="PHD">Doctorado</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" formControlName="institution" placeholder="UNACH">
                                            </td>
                                            <td>
                                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <div>
                                                        <button class="btn btn-icon btn-danger" type="button" (click)="removeTitles(item)">
                                                            <i class="bx bx-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="4" style="text-align: right;">
                                                <button type="button" class="btn btn-primary" (click)="addTitles()">
                                                    <i class="bx bx-plus"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-lg-12">
                            <h4>Actividad Docente Desempeñada</h4>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="activities">
                                    <thead>
                                        <tr>
                                            <th>Período</th>
                                            <th>Actividad</th>
                                            <th>Régimen de Dedicación</th>
                                            <th>Institución u Organismo</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of activities.controls; let i = index" [formGroupName]="i">
                                            <td>
                                                <input type="text" class="form-control" formControlName="period" placeholder="Ej. 2010 - 2012">
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" formControlName="activity" placeholder="Ej. Docente FAIN">
                                            </td>
                                            <td>
                                                <select class="form-select" formControlName="regime" required>
                                                    <option value="">Eliga un Régimen</option>
                                                    <option value="LIC">Tiempo Completo</option>
                                                    <option value="MG">Parcial</option>
                                                    <option value="PHD">Honorarios</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" formControlName="institution" placeholder="UNACH">
                                            </td>
                                            <td>
                                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <div>
                                                        <button class="btn btn-icon btn-danger" type="button" (click)="removeActivities(item)">
                                                            <i class="bx bx-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="5" style="text-align: right;">
                                                <button type="button" class="btn btn-primary" (click)="addActivities()">
                                                    <i class="bx bx-plus"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-lg-12">
                            <h4>Publicaciones</h4>
                            <div class="table-responsive">
                                <table class="table mb-0" formArrayName="publications">
                                    <thead>
                                        <tr>
                                            <th>ARTICULO DE REVISTA CIENTÍFICA O ACADEMICA</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <mat-accordion>
                                                    <mat-expansion-panel class="mb-3" hideToggle *ngFor="let item of publications.controls; let i = index" [formGroupName]="i">
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>
                                                                Titulo: {{item.value.title}}
                                                            </mat-panel-title>
                                                            <mat-panel-description>
                                                                Revista: {{item.value.magazine}}
                                                            </mat-panel-description>
                                                                <div style="position: absolute; top: 50%; right: 0; transform: translate(0%, -50%);">
                                                                    <button class="btn btn-icon btn-danger" type="button"
                                                                            (click)="removePublications(item)">
                                                                        <i class="bx bx-trash"></i>
                                                                    </button>
                                                                </div>
                                                        </mat-expansion-panel-header>
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="authors" id="authors">
                                                                    <label for="authors">Autor/es</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="title" id="title">
                                                                    <label for="title">Título del Artículo</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="magazine" id="magazine">
                                                                    <label for="magazine">Revista</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="year" id="year">
                                                                    <label for="year">Año</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="volume" id="volume">
                                                                    <label for="volume">Volumen</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="number" id="number">
                                                                    <label for="number">Número</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="pages" id="pages">
                                                                    <label for="year">Páginas</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="keyword" id="keyword">
                                                                    <label for="keyword">Palabras Clave</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="webLink" id="webLink">
                                                                    <label for="webLink">Vínculo Web Artículo</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <input type="text" class="form-control" formControlName="ddi" id="ddi">
                                                                    <label for="ddi">DOI Artículo</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-floating mb-3">
                                                                    <h5 for="revista">Revista Indexada En:</h5>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox" formControlName="isWosisi" id="isWosisi">
                                                                        <label class="form-check-label" for="isWosisi">WOS-ISI</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox" formControlName="isScopus" id="isScopus">
                                                                        <label class="form-check-label" for="isScopus">Scopus</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox" formControlName="isScielo" id="isScielo">
                                                                        <label class="form-check-label" for="isScielo">SciELO</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox" formControlName="isLatindex" id="isLatindex">
                                                                        <label class="form-check-label" for="isLatindex">Latindex</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox" formControlName="isIndenxed" id="isIndenxed">
                                                                        <label class="form-check-label" for="isIndenxed">No Indexada</label>
                                                                    </div>
                                                                    <div class="form-floating mb-3">
                                                                        <input type="text" class="form-control" formControlName="another" id="otra">
                                                                        <label for="otra">Otra</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td style="text-align: right;">
                                                <button type="button" class="btn btn-primary" (click)="addPublications()">
                                                    <i class="bx bx-plus"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button class="btn btn-light" type="button" (click)="cancel()"><i class="bx bxs-x-square"></i> Cancelar</button>
                        <button class="btn btn-primary" type="submit" (click)="save()"><i class="bx bx-save"></i> Guardar Currículum</button>
                    </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!-- end row -->
</form>