import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/shared/base-page';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { SiteSection } from 'src/app/core/models/site_section.model';
import { Rubric } from 'src/app/core/models/rubrica.model';
import { RubricService } from 'src/app/core/services/rubric.service';

@Component({
  selector: 'app-rubrica-formulario',
  templateUrl: './rubrica-formulario.component.html',
  styleUrls: ['./rubrica-formulario.component.scss']
})
export class RubricaFormularioComponent extends BasePage implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  formRubric: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    type: ['', [Validators.required]],
    isActive: [true],
    evalJSON: ['', [Validators.required]],
    minMR: ['', [Validators.required]],
    maxMR: ['', [Validators.required]],
    minR: ['', [Validators.required]],
    maxR: ['', [Validators.required]],
    minNR: ['', [Validators.required]],
    maxNR: ['', [Validators.required]],
    categories: this.fb.array([])
  });

  rubric?: Rubric = undefined;
  idRubric: number = 0;

  types = [
    {
      id: 'DIRECTIVE',
      name: 'DIRECTIVA'
    },
    {
      id: 'PEER',
      name: 'PARES'
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private rubricService: RubricService,
    injector: Injector
  ) { 
    super(injector);

    
  }

  async ngOnInit() {

    super.ngOnInit();
    this.openLoading();
    
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Rúbricas' },
      { label: 'Crear Rúbrica', active: true }
    ];

    this.route.params.pipe(
      switchMap(params => {
          this.idRubric = params['id'];

          if (this.idRubric) {
              return this.rubricService.getRubric(this.idRubric);
          }

          // If there's no idUser, just return an observable of null
          return of(null);
      })
    ).subscribe({
        next: (response) => {
            if (response ) {
                this.rubric = response;

                this.formRubric.patchValue({
                  name: this.rubric.name,
                  type: this.rubric.type
                });

                // Iterate over categories in the JSON data
                this.rubric.categories.forEach((categoryData: any) => {
                  const categoryGroup = this.fb.group({
                    name: '',
                    questions: this.fb.array([])
                  });

                  // Add category form group to the categories FormArray
                  this.categories.push(categoryGroup);

                  // Get the questions FormArray of the newly added category
                  const questionsArray = categoryGroup.get('questions') as FormArray;

                  // Iterate over questions in the category data
                  categoryData.questions.forEach((questionData: any) => {
                    // Create a new question form group
                    const questionGroup = this.fb.group({
                      question: '',
                      options: this.fb.array([])
                    });

                    // Patch the question form group
                    questionGroup.patchValue(questionData);

                    // Add the patched question group to the questions FormArray
                    questionsArray.push(questionGroup);

                    // Get the options FormArray of the newly added question
                    const optionsArray = questionGroup.get('options') as FormArray;

                    // Iterate over options in the question data
                    questionData.options.forEach((optionData: any) => {
                      // Create a new option form group
                      const optionGroup = this.fb.group({
                        option: '',
                        min: '',
                        max: ''
                      });

                      // Patch the option form group
                      optionGroup.patchValue({
                        option: optionData.option,
                        min: optionData.minValue,
                        max: optionData.maxValue
                      });

                      // Add the patched option group to the options FormArray
                      optionsArray.push(optionGroup);
                  });
                });
              });

            }

            this.breadCrumbItems = [
              { label: 'Rúbricas' },
              { label: 'Editar Rúbrica', active: true }
            ];

            this.closeLoading();
        },
        error: (error) => {
            console.error('Error fetching Rubric:', error);
            // Handle the error accordingly
            this.closeLoading();
        }
    });
  }

  get categories(): FormArray {
    return this.formRubric.get('categories') as FormArray;
  }

  questionsArray(i:number): FormArray {
    return this.categories.at(i).get('questions') as FormArray;
  }

  optionsArray(i:number, j:number): FormArray {
    return this.questionsArray(i).at(j).get('options') as FormArray;
  }

  addCategory() {
    const categoryForm = this.fb.group({
      // RubricCategory properties
      name: ['', [Validators.required]],
      questions: this.fb.array([])
    });
    this.categories.push(categoryForm);
  }

  addQuestion(categoryIndex: number) {
    const questionForm = this.fb.group({
      question: ['', [Validators.required]],
      options: this.fb.array([])
    });
    ((this.categories.at(categoryIndex).get('questions')) as FormArray).push(questionForm);
  }

  addOption(categoryIndex: number, questionIndex: number) {
    const optionForm = this.fb.group({
      option: ['', [Validators.required]],
      min: ['', [Validators.required]],
      max: ['', [Validators.required]]
    });
    this.optionsArray(categoryIndex, questionIndex).push(optionForm);
  }

  deleteCategory(categoryIndex: number) {
    this.categories.removeAt(categoryIndex);
  }

  deleteQuestion(categoryIndex: number, questionIndex: number) {
    const questions = (this.categories.at(categoryIndex).get('questions') as FormArray);
    questions.removeAt(questionIndex);
  }

  deleteOption(categoryIndex: number, questionIndex: number, optionIndex: number) {
    const questions = (this.categories.at(categoryIndex).get('questions') as FormArray);
    const options = (questions.at(questionIndex).get('options') as FormArray);
    options.removeAt(optionIndex);
  }


  async save() {
    this.openLoading();
    
    const formData = this.formRubric.getRawValue(); // Extracts the data from the form// Log the data to the console for debugging

    // Convert to JSON string if needed
    const jsonData = JSON.stringify(formData);

    if (this.idRubric) {
      //await this.rubricService.updateRubric(this.idRubric, this.formRubric.value);
    } else {
      let data = await this.rubricService.postRubric(jsonData);
      this.closeLoading();
      await this.globalSuccess(`La Rúbrica ${data.name}, ha sido guardado correctamente`);
      this.router.navigateByUrl('rubricas' );
    }
    /*const selectedSections = this.formRole.value.sections
      .map((checked: boolean, i: number) => checked ? this.allSections[i] : null)
      .filter((v: SiteSection | null) => v !== null);

    let datos = this.formRole.value;
    let request: RoleRequest = {
      name: datos.name,
      siteSections: selectedSections.map((s: SiteSection) => s.id)
    };

    this.openLoading();
    if (this.role) {
        let data = await this.roleService.putRole(request, this.role.id);
        this.closeLoading();
        await this.globalSuccess(`El Rol ${data.name}, ha sido guardado correctamente`);
        this.router.navigateByUrl('roles' );
    } else {
        let data = await this.roleService.postRole(request);
        this.closeLoading();
        await this.globalSuccess(`El Rol ${data.name}, ha sido creado correctamente`);
        this.router.navigateByUrl('roles');
    }*/
  }
 

  cancel(): void {
    this.router.navigateByUrl('rubricas');
  }

}
