import {Component, Input} from '@angular/core';
import { Project } from 'src/app/core/models/project.model';
import { OtherAcademicsView } from '../proyecto-detalle.component';

export interface Semester{
    name: string;
    year: number;
    semester: number;
}

@Component({
    selector: 'app-proyecto-view',
    templateUrl: './proyecto-view.component.html',
    styleUrls: ['./proyecto-view.component.scss']
})
export class ProyectoViewComponent {

    private _project?: Project;

    @Input() 
    set project(value: Project | undefined) {
        this._project = value;
        if (value) {
            this.initializeData();
        }
    }
    
    get project(): Project | undefined {
        return this._project;
    }
    
    semesters: Semester[] = [];
    otherAcademics: OtherAcademicsView[] = [];

    initializeData() {
        //bundle all other academics, grouped by rut
        const otherAcademics: OtherAcademicsView[] = [];
        for(let oa of this.project!.other_academics!){
            const foundOA = otherAcademics.find(oa => oa.rut === oa.rut);
            if(!foundOA){
                otherAcademics.push({
                    name: '',
                    rut: oa.rut,
                    activities: [
                        {
                            objective: oa.objective,
                            start_date: oa.start_date,
                            end_date: oa.end_date,
                        },
                    ],
                });
            }
            if(foundOA){
                foundOA.activities.push({
                objective: oa.objective,
                start_date: oa.start_date,
                end_date: oa.end_date,
                });
            }
        }
        this.otherAcademics = otherAcademics;

        this.calculateSemesters();
    }

    calculateSemesters() {
        //Todo: Get starting semester:
        let currentSemester = 2;
        let currentYear = 2024;
        let current_months = 0;
    
        const semesters: Semester[] = [];
    
        while(current_months < this.project!.estimated_duration_months!){
          // Get current semester
          semesters.push({
            name: `${currentYear} - ${currentSemester.toString().padStart(2, '0')}`,
            year: currentYear,
            semester: currentSemester
          });
          //advance to next semester
          currentSemester++;
          if(currentSemester > 2){
            currentSemester = 1;
            currentYear++;
          }
          current_months += 6;
        }
    
        this.semesters = semesters;
    }

    getProjectTypeLabel(projectType: string): string {
        switch (projectType) {
          case 'is_initial':
            return 'Iniciación (hasta 12 meses)';
          case 'is_regular':
            return 'Regular (hasta 24 meses)';
          case 'is_technology_transfer':
            return 'Transf. Tec. (hasta 24 meses)';
          case 'is_related_music':
            return 'Música';
          case 'is_related_literature':
            return 'Literatura';
          case 'other_related':
            return 'Otro (Indicar)';
          default:
            return '';
        }
    }
    
      getContractTypeName(contractTypeId: number| undefined): string {
        // logic to get contract type name by id
        return '';
      }
    
      getGradeName(grade: string| undefined): string {
        // logic to get grade name by id
        return '';
      }
    
      getObjectiveName(objectiveId: string| undefined): string {
        // logic to get objective name by id
        return '';
      }
    
      getPersonTotalHours(person:any): number {
        // logic to calculate total hours for a person
        return 0;
      }
    
      getTotalHours(): number {
        // logic to calculate total hours
        return 0;
      }

}