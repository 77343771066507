import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'src/app/core/models/action.model';
import { BasePage } from 'src/app/shared/base-page';
import { CurriculumResponse } from 'src/app/core/models/curriculum.model';
import { CurriculumService } from "../../../core/services/curriculum.service";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: 'app-curriculum-lista',
  templateUrl: './curriculum-lista.component.html',
  styleUrls: ['./curriculum-lista.component.scss']
})
export class CurriculumListaComponent extends BasePage implements OnInit {

  displayedColumns: string[] = ['rut', 'name', 'faculty', 'actions'];
  dataSource: MatTableDataSource<CurriculumResponse> = new MatTableDataSource<CurriculumResponse>();
  @ViewChild(MatSort) sort?: MatSort;

  curriculums: CurriculumResponse[] = [];

  constructor(injector: Injector, private curriculumService: CurriculumService) {
    super(injector);
  }

  ngOnInit(): void {
    this.getCallCurriculum();
  }

  async getCallCurriculum() {
    this.openLoading();
    this.curriculums = await this.curriculumService.getCurriculums();
    this.dataSource.data = this.curriculums;
    this.closeLoading();

    if(this.sort) this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  doAction(accion: Action, id: number){
    this.router.navigateByUrl(accion.url as string);
  }

  create(): void {
    this.router.navigateByUrl('/curriculos/crear');
  }

  async remove(row: CurriculumResponse) {
    let data = await this.curriculumService.deleteCurriculum(row.id);
    await this.globalSuccess(data.value);
    this.getCallCurriculum();
  }
}
