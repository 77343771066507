import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { RubricAplicationService } from "../../../core/services/rubric-aplication.service";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import * as moment from 'moment';
import { Reviewers } from "../../../core/models/user.model";
import {
    RubricApplication,
    RubricApplicationPatchRequest,
    RubricApplicationRequest
} from "../../../core/models/rubrica.model";

declare var $: any;

export class PeerReviewersTable extends RubricApplication {
    made: string = '';
    fullDate: string = '';
    type: string = 'PEER';
}

@Component({
    selector: 'app-pares-evaluadores',
    templateUrl: './pares-evaluadores.component.html',
    styleUrls: ['./pares-evaluadores.component.scss']
})
export class PeerReviewersComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;
    idProject!: number;

    displayedColumnsPeer: string[] = ['fullName', 'type', 'maxDate', 'made', 'submitDate', 'actions'];
    dataSourcePeer: MatTableDataSource<PeerReviewersTable> = new MatTableDataSource<PeerReviewersTable>();

    peerReviewers: PeerReviewersTable[] = [];
    idsPeerReviewersRemove: number[] = [];

    displayedColumnsReviewer: string[] = ['fullName', 'select'];
    dataSourceReviewer: MatTableDataSource<Reviewers> = new MatTableDataSource<Reviewers>();
    selection = new SelectionModel<Reviewers>(true, []);

    reviewers: Reviewers[] = [];

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private rubricAplicationService: RubricAplicationService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.route.params.subscribe(params => {
            this.idProject = params['id'];
            if (this.idProject) {
                this.get();
            }
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourcePeer.filter = filterValue.trim().toLowerCase();

        if (this.dataSourcePeer.paginator) {
            this.dataSourcePeer.paginator.firstPage();
        }
    }

    async get() {
        this.openLoading();
        this.peerReviewers = [];
        let peerReviewers: RubricApplication[] = await this.rubricAplicationService.getRubricAplications(this.idProject);
        peerReviewers.forEach(element => {
            let peerReviewers: PeerReviewersTable = {
                maxDate: new Date(),
                submitDate: element.submitDate,
                fullDate: moment(element.submitDate).format('EEEE, MMMM d, y'),
                submitted: element.submitted,
                made: (element.submitted ? "SI" : "NO"),
                id: element.id,
                user: element.user,
                actions: element.actions,
                type: element.type,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt
            };
            this.peerReviewers.push(peerReviewers);
        });
        this.dataSourcePeer.data = this.peerReviewers;
        this.closeLoading();
    }

    async save() {
        this.openLoading();
        let msg: string[] = [];
        for (const element of this.peerReviewers) {
            if (element.id === 0) {

                const maxDate = moment(element.maxDate).format('YYYY-MM-DD');

                let obj: RubricApplicationRequest = {
                    project: this.idProject,
                    user: element.user.id,
                    maxDate: maxDate,
                    type: element.type,
                    submitted: false
                };
                let data = await this.rubricAplicationService.postRubricAplication(obj);
                msg.push(data.value);
            } else if (element.id > 0 && !element.submitted) {
                const maxDate = moment(element.maxDate).format('YYYY-MM-DD');
                let obj: RubricApplicationPatchRequest = {
                    maxDate: maxDate
                };
                let data = await this.rubricAplicationService.patchRubricAplication(element.id, obj);
                msg.push(data.value);
            }
        }
        for (const element of this.idsPeerReviewersRemove) {
            let data = await this.rubricAplicationService.deleteRubricAplication(element);
            msg.push(data.value);
        }
        await this.globalSuccess(msg.join("<br>"));
        this.closeLoading();
    }

    cancel(): void {
        this.router.navigateByUrl('proyectos/lista');
    }

    async showReviewer() {
        this.openLoading();
        let idsPeers = this.peerReviewers.map(e => { return e.id });
        this.reviewers = [];
        let reviewers: Reviewers[] = await this.rubricAplicationService.getReviewers();
        reviewers.forEach(element => {
            if (!idsPeers.includes(element.id)) {
                this.reviewers.push(element);
            }
        });
        this.dataSourceReviewer.data = this.reviewers;
        this.closeLoading();
        $('#modalReviewer').modal('show');
    }

    filterReviewer(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceReviewer.filter = filterValue.trim().toLowerCase();
    }

    addReviewer() {
        this.selection.selected.forEach(element => {
            let peerReviewers: PeerReviewersTable = {
                submitDate: null, submitted: false, type: "PEER",
                maxDate: new Date(),
                fullDate: '',
                made: 'NO',
                id: 0,
                user: {
                    id: element.id,
                    email: '',
                    token: '',
                    isActive: true,
                    fullName: element.fullName,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    actions: []
                },
                actions: [],
                createdAt: new Date(),
                updatedAt: new Date()
            };
            this.peerReviewers.push(peerReviewers);
        });
        this.dataSourcePeer.data = this.peerReviewers;
        $('#modalReviewer').modal('hide');
    }

    removePeer(row: PeerReviewersTable) {
        if (row.id && row.id > 0) {
            this.idsPeerReviewersRemove.push(row.id);
        }
        this.peerReviewers.splice(this.peerReviewers.indexOf(row), 1);
        this.dataSourcePeer.data = this.peerReviewers;
    }

}
