import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Project, ProjectRequest } from '../models/project.model';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectWizardService {
  private projectData = new BehaviorSubject<ProjectRequest>({} as ProjectRequest);
  private lastModified = new BehaviorSubject<Date>(new Date());

  constructor(
    private projectService: ProjectService
  ) {  }

  getProjectData() {
    return this.projectData.asObservable();
  }

  async updateProjectData(data: Partial<ProjectRequest>, update: boolean = true) {
    
    if(update){//Update the backend with a service
      if (!this.projectData.value.id) {
        let resp = await this.projectService.postProject(data as ProjectRequest);
        //update ProjectData just the ID of resp to ProjectData
        this.projectData.next(ProjectRequest.fromProject(resp) as ProjectRequest);
        this.updateLastModified(new Date());
      } else {
        await this.projectService.putProject(this.projectData.value.id, data as ProjectRequest);
        this.updateLastModified(new Date());
      }
    } else {
      this.projectData.next({ ...this.projectData.value, ...data });
    }
  }

  getLastModified() {
    return this.lastModified.asObservable();
  }
  
  updateLastModified(date: Date) {
    this.lastModified.next(date);
  }

  getProjectObject(){
    return this.projectData.value;
  }
}
