<!-- start page title -->
<app-pagetitle title="Mis Acciones Pendientes" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->


<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4>Mis Acciones Pendientes (DIRINVEST)</h4>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead class="table-light">
                            <tr>
                                <th><b>Proyecto</b></th>
                                <th><b>Acción</b></th>
                                <th><b>Fecha Creación</b></th>
                                <th><b>Tiempo</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>Proyecto TEST 1</td>
                                <td>EVALUACION DIRINVEST</td>
                                <td>18/12/2023</td>
                                <td><span class="badge bg-warning">4 dias</span></td>
                                <td><button class="btn btn-icon btn-sm btn-primary" type="button" ngbTooltip="Ir a la Acción" (click)="goToDirectiveReview()"><i class="bx bx-right-arrow-circle"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4>Mis Acciones Pendientes (FACULTAD)</h4>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead class="table-light">
                            <tr>
                                <th><b>Proyecto</b></th>
                                <th><b>Acción</b></th>
                                <th><b>Fecha Creación</b></th>
                                <th><b>Tiempo</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>Proyecto TEST 1</td>
                                <td>FIRMADO CARTA APOYO</td>
                                <td>10/12/2023</td>
                                <td><span class="badge bg-danger">12 dias</span></td>
                                <td><button class="btn btn-icon btn-sm btn-primary" type="button" ngbTooltip="Ir a la Acción"><i class="bx bx-right-arrow-circle"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4>Mis Acciones Pendientes (PAR EVALUADOR)</h4>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead class="table-light">
                            <tr>
                                <th><b>Facultad</b></th>
                                <th><b>Acción</b></th>
                                <th><b>Fecha Creación</b></th>
                                <th><b>Tiempo</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>FAIN</td>
                                <td>EVALUACION PROYECTO</td>
                                <td>20/12/2023</td>
                                <td><span class="badge bg-success">1 día</span></td>
                                <td><button class="btn btn-icon btn-sm btn-primary" type="button" ngbTooltip="Ir a la Acción" (click)="goToPeerReview()"><i class="bx bx-right-arrow-circle"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- end row -->
