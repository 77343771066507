import { BaseModel } from "./base.model";
import { Project } from "./project.model";
import { User } from "./user.model";

export class Rubric extends BaseModel{
    name: string = '';
    type: string = '';
    isActive: boolean = true;
    evalJSON: string = '';
    categories: RubricCategory[] = [];
    internalComment?: string = '';
    investigatorComment?: string = '';
}

export class RubricRequest{
    name: string = '';
    type: string = '';
    isActive: boolean = true;
    evalJSON: Object = {};
}

export class RubricCategory extends BaseModel{
    name: string = '';
    questions: RubricQuestion[] = [];
}

export class RubricCategoryRequest{
    name: string = '';
    rubric: number = 0;
}

export class RubricQuestion extends BaseModel{
    question: string = '';
    answer: number = 0;
    options: RubricQuestionOption[] = [];
}

export class RubricQuestionRequest{
    question: string = '';
    rubricCategory: number = 0;
}

export class RubricQuestionOption extends BaseModel{
    option: string = '';
    minValue: number = 0;
    maxValue: number = 0;
}

export class RubricQuestionOptionRequest{
    option: string = '';
    minValue: number = 0;
    maxValue: number = 0;
    rubricQuestion: number = 0;
}

export class RubricApplication extends BaseModel{
    type: string = '';
    maxDate: Date = new Date();
    submitted: boolean = false;
    submitDate: Date | null = null;
    user!: User;
    project?: Project;
    internalComment?: string = '';
    investigatorComment?: string = '';
    totalScore?: number = 0;
    qualitativeEvaluation?: string = '';
    Answers?: any;
}

export class RubricApplicationRequest {
    type: string = '';
    maxDate:string = '';
    project: number = 0;
    user: number = 0;
    submitted: boolean = false;
}

export class RubricApplicationPatchRequest {
    maxDate:string = '';
}

export class RubricAnswer extends BaseModel{
    question: string = '';
    value: number = 0;
}

export class RubricAnswerRequest {
    idQuestion: number = 0;
    value: number = 0;
}
