<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Currículums</h4>
                <div class="d-flex">
                    <div class="p-2 w-100">
                        <mat-form-field>
                            <mat-label>Filtro</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese términos para buscar..." #input>
                        </mat-form-field>
                    </div>
                    <div class="p-2">
                        <button type="button" class="btn btn-primary" (click)="create()">
                            <i class="bx bx-plus"></i> Nuevo Currículum
                        </button>
                    </div>
                </div>

                <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="rut">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Rut </th>
                            <td mat-cell *matCellDef="let row"> {{row.rut}} </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="faculty">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Facultad </th>
                            <td mat-cell *matCellDef="let row"> {{row.faculty}} </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <div *ngFor="let action of row.actions">
                                        <button type="button" class="btn btn-primary" (click)="doAction(action, row.id)" [ngbTooltip]="action.name">
                                            <i class="{{action.icon}}"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <button class="btn btn-icon btn-danger" type="button" (click)="remove(row)">
                                            <i class="bx bx-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No se encontraron Currículums</td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- end row -->