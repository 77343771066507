import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from 'src/app/shared/base-page';
import { Faculty } from 'src/app/core/models/faculty.model';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { of, switchMap, tap } from 'rxjs';
import { Career, CareerRequest } from 'src/app/core/models/career.model';
import { CarrerasService } from 'src/app/core/services/carreras.service';


@Component({
    selector: 'app-mis-pendientes',
    templateUrl: './mis-pendientes.component.html',
    styleUrls: ['./mis-pendientes.component.scss']
})
export class MyPendingActionsComponent extends BasePage implements OnInit {
    // bread crumb items
    breadCrumbItems!: Array<{}>;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private facultadService: FacultadesService,
        private carreraService: CarrerasService,
        injector: Injector
    ) {
        super(injector);
    }

    override async ngOnInit() {
        super.ngOnInit();
    }

    goToPeerReview(): void {
        this.router.navigateByUrl('evaluacion-pares');
    }

    goToDirectiveReview(): void {
        this.router.navigateByUrl('evaluacion-dirinves');
    }


}
