import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {environment} from "../../../environments/environment";
import {Curriculum, CurriculumRequest, CurriculumResponse} from "../models/curriculum.model";
import {Mensaje} from "../models/utils.model";

@Injectable({
  providedIn: 'root'
})
export class CurriculumService {

  constructor(private http: HttpClient) {
  }

  getCurriculums(): Promise<CurriculumResponse[]> {
    return lastValueFrom(this.http.get<CurriculumResponse[]>(`${environment.apiURL}/curriculum`));
  }

  getCurriculum(id: number): Promise<Curriculum> {
    return lastValueFrom(this.http.get<Curriculum>(`${environment.apiURL}/curriculum/${id}`));
  }

  postCurriculum(data: CurriculumRequest): Promise<Mensaje> {
    return lastValueFrom(this.http.post<Mensaje>(`${environment.apiURL}/curriculum`, data));
  }

  putCurriculum(id: number, data: CurriculumRequest): Promise<Mensaje> {
    return lastValueFrom(this.http.post<Mensaje>(`${environment.apiURL}/curriculum/${id}`, data));
  }

  deleteCurriculum(id: number): Promise<Mensaje> {
    return lastValueFrom(this.http.delete<Mensaje>(`${environment.apiURL}/curriculum/${id}`));
  }

}