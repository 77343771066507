import { CdkStepper } from '@angular/cdk/stepper';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Career } from 'src/app/core/models/career.model';
import { Faculty } from 'src/app/core/models/faculty.model';
import { Project, ProjectRequest } from 'src/app/core/models/project.model';
import { CarrerasService } from 'src/app/core/services/carreras.service';
import { FacultadesService } from 'src/app/core/services/facultades.service';
import { ProjectWizardService } from 'src/app/core/services/project-wizard.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { BasePage } from 'src/app/shared/base-page';
import { NgForm } from '@angular/forms';
import { CallPeriodService } from 'src/app/core/services/call-period.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-proyecto-wizard',
  templateUrl: './proyecto-wizard.component.html',
  styleUrls: ['./proyecto-wizard.component.scss']
})
export class ProyectoWizardComponent extends BasePage implements OnInit {

  @ViewChild('cdkStepper') stepper!: CdkStepper;
  @ViewChild('projectForm') projectForm!: NgForm;
  
  firstFormGroup!: FormGroup;
  currentStepIndex: number = 0;
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  project?: Partial<ProjectRequest> = undefined;
  faculty: number = 0;
  career: number = 0;
  name: string = "";
  folio: string = "";
  cpId: number = 1;
  cpCode: string = "";
  type: string = "PI";

  lastModified: Date = new Date();

  faculties: Array<Faculty> = [];
  careers: Array<Career> = [];

  constructor(
    private _formBuilder: FormBuilder, 
    private projectWizardService: ProjectWizardService,
    private facultiesService: FacultadesService,
    private careersService: CarrerasService,
    private projectService: ProjectService,
    private callPeriodService: CallPeriodService,
    private route: ActivatedRoute,
    injector: Injector) 
  { super(injector); }

  async ngOnInit() {
    super.ngOnInit();
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Proyecto' },
      { label: 'Crear Proyecto', active: true }
    ];

    this.openLoading();
    const openPeriods = await this.callPeriodService.getCurrentCallPeriod();
    if(!openPeriods){
      await this.globalError({message: "No hay Convocatorias abiertas en éste momento, no se puede crear un Proyecto"});
      this.router.navigateByUrl('/');
    } else {
      this.cpId = openPeriods.id;
      this.cpCode = openPeriods.code;
    }
    this.faculties = await this.facultiesService.getTodasFacultadesActivas();
    this.careers = await this.careersService.getTodasCarrerasActivas();

    this.route.paramMap.subscribe(params => {
      const projectId = Number(params.get('id'));
      // Call a method to fetch project data based on the ID
      if(projectId){
        this.loadProject(projectId);
      } else {
        this.closeLoading();
      }
      
    });
    

    this.firstFormGroup = this._formBuilder.group({
      // define controls and validators
    });
    // Initialize other form groups for each step
  }

  setLastModified(date: Date) {
    this.lastModified = date;
  }

  async loadProject(projectId: number) {
    try {
      // Call your project service to fetch project data based on the ID
      const project = await this.projectService.getProject(projectId);
      // Update component properties with the fetched project data
      this.project = ProjectRequest.fromProject(project);
      // You may also need to update other properties like faculty, career, etc.
      this.faculty = this.project.faculty || 0;
      this.career = this.project.career || 0;
      this.name = project.title;
      this.folio = project.folio;

      this.updateData(this.project, false);
      this.closeLoading();
      // Call any other methods that depend on the project data
      // For example, if you need to fetch additional data based on the project
      // this.getFolio();
    } catch (error) {
      // Handle error if project data cannot be fetched
      console.error('Error fetching project data:', error);
      this.closeLoading();
    }
  }

  careersForFaculty(facultyId: number) {
    if(facultyId === 0) return this.careers;
    return this.careers.filter(career => career.faculty?.id == facultyId);
  }

  async updateData(updatedFields: Partial<ProjectRequest>, updateBackend: boolean = true) {
    this.openLoading();
    await this.projectWizardService.updateProjectData(updatedFields, updateBackend);
    this.closeLoading();
  }

  nextStep(project?: Partial<ProjectRequest>) {
    if(project) this.updateData(project);
    this.stepper.next();
  }
  
  previousStep(project?: Partial<ProjectRequest>) {
    if(project) this.updateData(project);
    this.stepper.previous();
  }

  create() {
    if (this.projectForm.valid) {
      this.project = {
        type: this.type,
        folio: this.folio,
        title: this.name,
        callPeriod: this.cpId,
        faculty: this.faculty,
        career: this.career
      }

      this.updateData(this.project, true);
    }
  }

  async getFolio() {
    this.folio = await this.projectService.getNextFolio(this.cpId, this.faculty);
  }
}
