import { NgxMatNativeDateAdapter } from '@angular-material-components/datetime-picker';
import * as moment from 'moment';

export class CustomDateAdapter extends NgxMatNativeDateAdapter {
    override getFirstDayOfWeek(): number {
        // Monday is the first day of the week
        return 1;
    }

    // Format the date, according to CUSTOM_DATE_FORMATS
    override format(date: Date, displayFormat: Object): string {
        return moment(date).format(displayFormat.toString());
    }
}