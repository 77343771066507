<!-- start page title -->
<app-pagetitle title="Formulario Proyecto" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-2">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="callperiod" readonly value="202401">
                            <label for="callperiod">Convocatoria</label>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="folio" name="folio" readonly [value]="project?.folio">
                            <label for="folio">Folio</label>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="title" name="title" placeholder="Ej. Proyecto de Investigación" [value]="project?.title">
                            <label for="title">Título Proyecto</label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingSelectFacu" name="faculty" readonly [value]="project?.faculty?.name">
                            <label for="floatingSelectFacu">Facultad / Unidad</label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingSelect" name="career" readonly [value]="project?.career?.name">
                            <label for="floatingSelect">Programa / Carrera</label>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <input type="text" class="form-control" id="projectType" name="type" readonly [value]="project?.type">
                            <label for="projectType">Tipo Proyecto</label>
                    </div>
                </div>
            </div>
        </div>

        <app-proyecto-view [project]="project"></app-proyecto-view>

        <div class="card" *ngIf="project">
            <div class="card-body">
                <h4>Proceso de Adjudicación <span class="badge bg-info">{{percentage}}%</span></h4>
                <p class="mb-4">
                    <ngb-progressbar type="info" [value]="percentage" [striped]="true" [animated]="true" height="40px">
                    </ngb-progressbar>
                </p>
                <div ngbAccordion #accordion="ngbAccordion" activeIds="config-panel-one" [closeOthers]="true">
                    <div class="card rounded mb-3"></div>
                    <div ngbAccordionItem="config-panel-one" id="config-panel-one">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Currículums 
                                <span class="badge" [ngClass]="amount_of_academics_with_curriculum === allAcademics.length ? 'bg-success' : 'bg-warning'">
                                    {{amount_of_academics_with_curriculum}} / {{allAcademics.length}}
                                </span>
                            </h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>RUT</th>
                                                    <th>Investigador</th>
                                                    <th>Facultad</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr [ngClass]="academic.hasCurriculum ? 'table-success' : 'table-warning'" *ngFor="let academic of allAcademics">
                                                    <th scope="row">{{academic.rut}}</th>
                                                    <td>{{academic.name}}</td>
                                                    <td>{{academic.faculty}}</td>
                                                    <td>
                                                        <button *ngIf="academic.hasCurriculum" [disabled]="project.current_status! == 'C'" type="button" class="btn btn-primary btn-sm" (click)="goToEditCV(academic.curriculumId)" ngbTooltip="Editar Currículum"><i class="bx bx-pencil"></i></button>
                                                        <button *ngIf="!academic.hasCurriculum" [disabled]="project.current_status! == 'C'" type="button" class="btn btn-primary btn-sm" (click)="goToNewCV(academic.rut)" ngbTooltip="Agregar Currículum"><i class="bx bx-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-two">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Cartas de Apoyo 
                                <span class="badge" [ngClass]="amount_of_support_letters_signed === supportLetters.length ? 'bg-success' : 'bg-warning'">
                                    {{amount_of_support_letters_signed}} / {{supportLetters.length}}
                                </span>
                            </h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Facultad</th>
                                                    <th>Encargado</th>
                                                    <th>¿Firmada?</th>
                                                    <th>Fecha Firma</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr [ngClass]="sp.isSigned ? 'table-success' : 'table-warning'" *ngFor="let sp of supportLetters">
                                                    <th scope="row">{{sp.faculty.acronym}}</th>
                                                    <td>{{sp.user.fullName}}</td>
                                                    <td>{{sp.isSigned? 'SI': 'NO'}}</td>
                                                    <td>{{sp.dateSigned | date:'dd/MM/yyyy'}}</td>
                                                    <td><button type="button" class="btn btn-primary btn-sm" (click)="goToSupportLetter(sp.id)" ngbTooltip="Editar Carta de Apoyo"><i class="bx bx-pencil"></i></button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-three">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Evaluaciones 
                                <span class="badge" [ngClass]="amount_of_evals_submitted === evals.length ? 'bg-success' : 'bg-warning'">
                                    {{amount_of_evals_submitted}} / {{evals.length}}
                                </span>
                            </h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template ngbPanelContent>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Tipo</th>
                                                    <th>¿Realizada?</th>
                                                    <th>Fecha Eval</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr [ngClass]="e.submitted ? 'table-success' : 'table-warning'" *ngFor="let e of evals">
                                                    <th scope="row">{{e.type === 'PEER'? 'PARES': 'DIRINVEST'}}</th>
                                                    <td>{{e.submitted? 'SI': 'NO'}}</td>
                                                    <td>{{e.submitDate | date: 'dd/MM/yyyy'}}</td>
                                                    <td *ngIf="e.submitted"><button type="button" class="btn btn-primary btn-sm" (click)="goToViewEval(e.id, e.type)" ngbTooltip="Ir a Evaluación"><i class="bx bx-search-alt"></i></button></td>
                                                    <td *ngIf="!e.submitted"><button type="button" class="btn btn-primary btn-sm" (click)="goToEval(e.id, e.type)" ngbTooltip="Realizar Evaluación"><i class="bx bx-check-square"></i></button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-four">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Actas de Consejo <span class="badge" [ngClass]="amount_of_minutes_saved === minutes.length ? 'bg-success' : 'bg-warning'">
                                {{amount_of_minutes_saved}}/{{minutes.length}}
                            </span></h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template ngbPanelContent>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Titulo Acta</th>
                                                    <th>Fecha</th>
                                                    <th>Estado</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr [ngClass]="m.date_council ? 'table-success' : 'table-warning'" *ngFor="let m of minutes">
                                                    <th scope="row">{{m.title || 'NO REALIZADA'}}</th>
                                                    <td>{{m.date_council | date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{m.status || '-'}}</td>
                                                    <td><button *ngIf="!m.date_council" type="button" class="btn btn-primary btn-sm" (click)="goToMinute(m.id)" ngbTooltip="Crear Acta Comité"><i class="bx bx-pencil"></i></button></td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-five">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Costos Indirectos 
                                <span class="badge" [ngClass]="amount_of_indirect_costs_signed === indirectCosts.length ? 'bg-success' : 'bg-warning'">
                                    {{amount_of_indirect_costs_signed}} / {{indirectCosts.length}}
                                </span>
                            </h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template ngbPanelContent>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Facultad</th>
                                                    <th>Encargado</th>
                                                    <th>¿Firmada?</th>
                                                    <th>Fecha Firma</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr [ngClass]="ic.isSigned ? 'table-success' : 'table-warning'" *ngFor="let ic of indirectCosts">
                                                    <th scope="row">{{ic.faculty.acronym}}</th>
                                                    <td>{{ic.user.fullName}}</td>
                                                    <td>{{ic.isSigned? 'SI': 'NO'}}</td>
                                                    <td>{{ic.dateSigned | date:'dd/MM/yyyy'}}</td>
                                                    <td><button type="button" class="btn btn-primary btn-sm" (click)="goToIndirectCosts(ic.id)" ngbTooltip="Editar Costos Indirectos"><i class="bx bx-search"></i></button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="config-panel-six">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Contratos 
                                <span class="badge" [ngClass]="amount_of_contracts_signed === contracts.length ? 'bg-success' : 'bg-warning'">
                                    {{amount_of_contracts_signed}} / {{contracts.length}}
                                </span>
                            </h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template ngbPanelContent>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Fecha Inicio</th>
                                                    <th>Fecha Fin</th>
                                                    <th>¿Firmada?</th>
                                                    <th>Fecha Firma</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr [ngClass]="c.isSigned ? 'table-success' : 'table-warning'" *ngFor="let c of contracts">
                                                    <th scope="row">{{c.startDate | date:'DD/MM/YYYY'}}</th>
                                                    <td>{{c.endDate | date:'DD/MM/YYYY'}}</td>
                                                    <td>{{c.isSigned? 'SI': 'NO'}}</td>
                                                    <td>{{c.dateSigned | date:'dd/MM/yyyy'}}</td>
                                                    <td><button type="button" class="btn btn-primary btn-sm" (click)="goToContract(c.id)" ngbTooltip="Ir a Contrato"><i class="bx bx-search"></i></button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4>Ejecución del Proyecto</h4>
                <div ngbAccordion #accordion="ngbAccordion" activeIds="config-panel-one" [closeOthers]="true">
                    <div class="card rounded mb-3"></div>
                    <div ngbAccordionItem="config-panel-one" id="config-panel-one">
                        <h2 ngbAccordionHeader>
                            <button ngbAccordionButton><h5>Informes de Avance <span class="badge bg-info">2</span></h5></button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template>
                                    <button class="btn btn-primary add-btn" (click)="goToPR()"><i class="bx bx-plus"></i> Agregar Informe de Avance</button>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>Estado</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="table-success">
                                                    <th scope="row">28/02/2024</th>
                                                    <td>ENVIADO</td>
                                                    <td><button type="button" class="btn btn-primary btn-sm" (click)="goToPR()" ngbTooltip="Ver Informe de Avance"><i class="bx bx-search"></i></button></td>
                                                </tr>
                                                <tr class="table-warning">
                                                    <th scope="row">15/03/2024</th>
                                                    <td>CREADO</td>
                                                    <td>
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="goToPR()" ngbTooltip="Editar Informe de Avance"><i class="bx bx-pencil"></i></button>
                                                        <button style="margin-left: 5px" type="button" class="btn btn-primary btn-sm" (click)="goToPR()" ngbTooltip="Enviar Informe de Avance"><i class="bx bx-paper-plane"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4>Finalización del Proyecto</h4>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-0 mb-md-3">
                            <label for="formFileLg" class="form-label">Cargar Producto Final</label>
                            <input class="form-control form-control-lg" id="formFileLg" type="file">
                        </div>
                    </div>
                    <div class="col-lg-12" style="text-align:center;">
                        <button class="btn btn-danger btn-lg"><i class="bx bx-check-circle"></i> Finalizar Proyecto</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
